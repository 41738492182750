$( window ).on("load", function() {

  checkCookies();

  /*** cookies ***/
  function checkCookies(){
    var cookiesAgreement = localStorage.getItem('npkrcm-cookies-agreement'),
    cookiesNote = $('.cookies-notification'),
    cookiesBtn = $('.cookies-notification__btn'),
    cookiesBtnClose = $('.cookies-notification__close');

    if(!cookiesAgreement){
      cookiesNote.addClass('show');
    }

    cookiesBtn.add(cookiesBtnClose).on( 'click', function() {
      localStorage.setItem( 'npkrcm-cookies-agreement', "true");
      cookiesNote.removeClass('show');
    });
  }


  /*** слайдеры ***/
  var  slider = 'slider';

  $('.swiper-default').each(function (i) {
    var slider_class = slider + '_' + i,
        slider_init = '.' + slider + '_' + i,
        next_slider = 'slider--next_' + i,
        prev_slider = 'slider--prev_' + i,
        pagination_slider = 'slider-pagination_' + i;

    $(this).addClass(slider_class);
    $(this).parents('.carousel').find(".swiper-nav__arrow.--prev").addClass(prev_slider);
    $(this).parents('.carousel').find(".swiper-nav__arrow.--next").addClass(next_slider);
    $(this).parents('.carousel').find(".swiper-pagination").addClass(pagination_slider);

      new Swiper(slider_init, {
        autoHeight: true, //enable auto height
        loop: true,
        speed: 600,
        slidesPerView: 1,

        pagination: {
          el: ('.' + pagination_slider),
          type: 'fraction',
        },
        navigation: {
          nextEl: ('.' + next_slider),
          prevEl: ('.' + prev_slider),
        }
      });
  });

  /*** accordion ***/
  var accordionFaq = $('.js-acc');

  $('.js-acc .acc__btn').on('click', function() {
    var self = $(this),
        activeClass = 'acc--active',
        body = '.acc__content';

      self
      .addClass(activeClass)
      .siblings(body)
      .slideToggle(200);

      self
      .parent()
      .siblings()
      //.not(self)
      .find('.acc__btn')
      .removeClass(activeClass)
      .siblings(body)
      .slideUp(200);

      setTimeout(function() {
          if (self.siblings(body).css('display') == 'none') {
              self.removeClass(activeClass);
          }
      }, 250);
  });


  /*** popup ***/
  var $modalOpen = $('.modal-open'),
      $modalClose = $('.modal__close');

   $modalOpen.magnificPopup({
     type: 'inline',
     preloader: false,
     fixedContentPos: true,
     showCloseBtn: false,
     mainClass: 'mfp-move',
     removalDelay: 300
   });

   $modalClose.on('click', function(e) {
       e.preventDefault();
       $.magnificPopup.close();
   });

   //ajax
   var $form = $('.js-form');

   $form.trigger('reset');
   $form.on('submit', function(e) {
     e.preventDefault();
     var url = $(this).attr('action');

         $.ajax({
           url: url,
           type: 'POST',
           contentType: false,
           processData: false,
           data: new FormData(this),
           success: function(msg) {
             if (msg == 'ok') {
               $.magnificPopup.open({
                  items: {
                      src: '#modal-thanks'
                  }
               });
               $('#form').trigger('reset');
             }
           }
         });
   });

});
